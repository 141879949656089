<template>
  <div>
    <div class="h1">退款政策</div>
    <div class="layout">
      <div class="rich-text">
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>

        <p>
          <strong><span style="font-size: 18px;">1.1 订单确认</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          1.1.1 部分指定活动或服务接受即时确认。除此类活动或服务外，任何在本网站所述的确认时间仅供参考。实际所需的确认时间，会因应情况而有所调整。
        </p>
        <p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.2 购买及使用凭证</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1.2.1 透过本网站，你可订购由目的地供应商所提供的服务的电子凭证（下称「凭证」）。在相关供应商的条款限制下，你将收到一封确认你订单的电子邮件，其中包含凭证的确认号码（下称「确认号码」），以及可列印的版本。 </span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.2.2 使用你的凭证时，你须准时到达相关供应商指定的集合地点，且提供该供应商要求的文件及/或资料，例如你的预订确认号码及/或列印的凭证。若你无法准时出现，或未能提供必要的文件或资料，你将不会享受该服务或退款。 </span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.2.3 供应商可能要求你提供载有你照片的身份文件，才准许你使用凭证。若你遗失凭证、或凭证被破坏、遭偷窃，一游 UNU TRAVEL或供应商均无需负上责任。此外，若法律禁止提供相关服务，凭证将失效。若你试图以不合法的形式兑换凭证（比方说你欲参加品酒活动，但未达能使用该服务的规定年龄），相关供应商将可拒绝接受你的凭证，且无需安排退款。 </span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.3 凭证之《使用条款》</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.3.2 凭证是一次项目（下称「项目」）的入场券；凭证上会载明可使用的期限。除非凭证上另有明白规定，否则若你未能在凭证上载明的期限内使用凭证，将不获退款。 </span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.4 取消凭证</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1.4.1 你可在订购时在网站上载明的取消期限内，联络一游 UNU TRAVEL的顾客服务，取消你的凭证。取消的联络窗口因个案而异。在成功取消使用凭证且收到必要的通知后，金额将会全数退款至你用来订购该凭证的付款帐户中。 </span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1.4.2 提供服务该项目的是供应商，而非一游 UNU TRAVEL，因此该项目的供应商应独自接受或拒绝你订购的任何凭证，并负责提供所有相关的服务。 </span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.4.3 若你对该项凭证的服务有任何疑问或投诉，请向相关供应商查询。所有凭证已支付的费用均不可退款，除非本《使用条款》明确规定。凭证是以订购当日在网站上载明的货币来报价。 </span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.4.4 若你所订购的项目被相关供应商取消，一游 UNU TRAVEL会尽快以合理的方法通知你，并会将金额全数退款到你用来订购该凭证的付款帐户中。 </span>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.5 要求协助</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.5.1 若你试图根据本《使用条款》及适用于该凭证的相关使用条款来兑换使用凭证，但你无法成功使用（因为供应商的错误或其他原因），请透过电子邮箱support@unutravel.com联络一游 UNU TRAVEL，一游 UNU TRAVEL将尝试接触并与供应商联系以寻求协商。
        </p>
        <p class="p1">
          <br/>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.6 额外收费</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.6.1 一游 UNU TRAVEL保留权利以为所提供的任何服务向你提出付款要求或收费。你应依照本网站的说明，支付你选择的服务所需的所有适用的费用。
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.7 收费更改</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.7.1 一游 UNU TRAVEL保留随时变更价格及收费的权利，变更的内容可能透过电子邮件或张贴在网站，若你于该等变更的通知发出后使用，或继续使用该服务，意味阁下同意并接纳变更后的价格及收。
        </p>
        <p class="p1">
          <br/>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.8 一游 UNU TRAVEL的权利及义务</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.8.1 一游 UNU TRAVEL保留绝对酌情权以拒绝或取消任何可能违反此条款的服务订单或订购。
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.8.2 一游 UNU TRAVEL致力提供或向供应商争取以市场上的最优价值提供服务。你确认了解及认同任何于使用个别服务时所产生的税项或额外费用，皆明确地排除在最优价格计算之外。
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;"><span
          class="s1">1.8.3 尽管供应商被要求向一游 UNU TRAVEL网站上之服务提供准确及最新的价格，一游 UNU TRAVEL无法保证所提供的服务于任何时候皆准确无误。 </span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.9 以上条款及细则及退款政策适用于全球的一游 UNU TRAVEL用户。 </span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
          <br/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'RefundCn'
  };
</script>
<style scoped>
</style>
