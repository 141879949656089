<template>
  <div>
    <div class="h1">Cookie政策</div>
    <div class="layout">
      <div class="rich-text">
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">一遊</span><span class="s2">UNU TRAVEL</span><span
          class="s1">致力改善你在旅途中使用本網頁的體驗。我們将運用</span><span class="s2">cookie</span><span
          class="s1">管理網頁務求為你帶來更佳的網頁使用經驗。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">我們將極力保護你的個人隱私。如欲了解更多資訊，請參考</span><span class="s3">私隱政策及免責聲明</span><span class="s1">。</span>
        </p>
        <p class="p2">
          <span class="s4"><br/></span>
        </p>
        <p class="p2">
          <span class="s4">以下說明一遊</span><span class="s1">UNU TRAVEL</span><span class="s4">運用</span><span
          class="s1">cookie</span><span class="s4">的基本規範。</span>
        </p>
        <p class="p2">
          <span class="s4"><br/></span>
        </p>
        <p class="p2">
          <span class="s1"><strong>cookie</strong></span><span class="s4"><strong>是什麼？</strong></span>
        </p>
        <p class="p2">
          <span class="s4"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s2">Cookie</span><span class="s1">是儲存於你瀏覽器的小型文字檔，多數網路都有使用，用於儲存你的網路使用，例如登入資料與瀏覽記錄。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">Cookie</span><span class="s1">可根據</span><span class="s2">(i)</span><span
          class="s1">時效與</span><span class="s2">(ii)</span><span class="s1">其設置機構分類：</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em>(i)</em></strong></span><span class="s4"><strong>暫時性</strong></span><span
          class="s1"><strong><em>Cookies</em></strong></span><span class="s4"><strong>與持久性</strong></span><span
          class="s1"><strong><em>Cookie</em></strong></span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em><br/></em></strong></span>
        </p>
        <p class="p1">
          <span class="s2">Cookies</span><span class="s1">時效取決於他們是暫時性或持久性。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>暫時性</strong></span><span class="s2"><strong>Cookies</strong></span><span
          class="s1">會在你關閉瀏覽器後自動刪除。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>持久性</strong></span><span class="s2"><strong>Cookies</strong></span><span
          class="s1">會在使用者的電腦或手機設備上儲存一段固定時間。當你瀏覽網站時，瀏覽器會重新取回這些</span><span class="s2">Cookies</span><span
          class="s1">，方便網站識別你的使用設備，並根據你前次的瀏覽記錄，提供個人化的網站體驗。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em>(ii)</em></strong></span><span class="s4"><strong>第一方及第三方</strong></span><span
          class="s1"><strong><em>Cookies</em></strong></span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em><br/></em></strong></span>
        </p>
        <p class="p1">
          <span class="s1">我們控管<strong>第一方</strong></span><span class="s2"><strong>Cookies</strong></span><span
          class="s1">的運用範圍，但第三方</span><span class="s2">Cookies</span><span
          class="s1">由第三方網站操作，我們無控制權限。本網站的部分頁面包含嵌入式內容形式的第三方</span><span class="s2">Cookies</span><span
          class="s1">，例如</span><span class="s2">JavaScript</span><span class="s1">和</span><span
          class="s2">API</span><span class="s1">。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>為什麼我們要使用</strong></span><span
          class="s2"><strong><em>Cookies</em></strong></span><span class="s1"><strong>？</strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1">為了確保安全、有效率且個人化的服務，我們將</span><span class="s2">Cookies</span><span class="s1">技術應用於以下用途：</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">確保網站功能有效運作；</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">檢視訪客如何進入及使用網站；</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">分析旅客喜愛的熱門旅遊地點；</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">了解你對推廣與宣傳活動的興趣；</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">推薦你會感興趣的旅遊地點；以及</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">改善網站內容與使用者體驗</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">除了以上用途外，我們保證絕不將</span><span class="s2">Cookies</span><span class="s1">用於其他目的。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>我們使用哪種</strong></span><span class="s2"><strong><em>Cookies</em></strong></span><span
          class="s1"><strong>類型？</strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1">我們使用的</span><span class="s2">Cookies</span><span class="s1">共有四種類型，「絕對必要</span><span
          class="s2">Cookies</span><span class="s1">」、「必要</span><span class="s2">Cookies</span><span
          class="s1">」、「分析</span><span class="s2">Cookies</span><span class="s1">」、「廣告</span><span
          class="s2">Cookies</span><span class="s1">」。如欲了解更多資訊，請查看下方表格：</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <table class="t1">
          <tbody>
          <tr class="firstRow">
            <td valign="middle" class="td1 selectTdClass" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s1"><strong>Cookies</strong></span>
              </p>
            </td>
            <td valign="middle" class="td2 selectTdClass" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1"><strong>說明</strong></span>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="td3 selectTdClass" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s4">絕對必要</span><span class="s1">Cookies</span>
              </p>
            </td>
            <td valign="middle" class="td4 selectTdClass" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1">此類型的</span><span class="s2">Cookies</span><span
                class="s1">能協助我們確保網站功能運作正常，同時提供你導覽本網站的服務、更便利的使用體驗。</span><span class="s2"><br/></span><span
                class="s1">如果你停用或封鎖此類型</span><span class="s2">Cookies</span><span
                class="s1">，我們將無法提供你本網站的服務與部分功能。</span><span class="s2"><br/></span><span class="s1">我們也將此類型</span><span
                class="s2">Cookies</span><span class="s1">應用於詐欺偵測與預防等用途。此外，透過此類型</span><span
                class="s2">Cookies</span><span class="s1">，使用者登入後，瀏覽本網站不同頁面時便無須再次登入。</span>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="td5 selectTdClass" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s4">分析</span><span class="s1">Cookies</span>
              </p>
            </td>
            <td valign="middle" class="td6 selectTdClass" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1">我們使用</span><span class="s2">Google Analytics</span><span
                class="s1">和其他分析工具，蒐集以下匿名統計資料：</span><span class="s2"><br/>•&nbsp;</span><span
                class="s1">評估訪客如何使用本網站；</span><span class="s2"><br/>•&nbsp;</span><span class="s1">瞭解訪客基本資訊與所在位置；</span><span
                class="s2"><br/>•&nbsp;</span><span class="s1">評估用戶如何接收我們的新產品，檢視行銷活動成效；</span><span class="s2"><br/>•&nbsp;</span><span
                class="s1">提供個人化網站，改善網站內容與服務。</span><span class="s2"><br/></span><span
                class="s1">依據蒐集的匿名數據資料，此類型分析工具能協助彙整趨勢分析與報告紀錄，以達到上述用途。</span>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="td7 selectTdClass" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s4">廣告</span><span class="s1">Cookies</span>
              </p>
            </td>
            <td valign="middle" class="td8 selectTdClass" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1">此類型</span><span class="s2">Cookies</span><span class="s1">能識別你的</span><span
                class="s2">IP</span><span
                class="s1">位址，追蹤並記憶你在不同網站上的使用習慣與喜好，以便提供與你相關、興趣相符的廣告內容，並減少你在本網站上看到相同廣告的次數。</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
        <p class="p2">
          <span class="s4"><strong><br/></strong></span>
        </p>
        <p class="p2">
          <span class="s4"><strong>同意接受</strong></span><span class="s1"><strong><em>Cookies</em></strong></span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em><br/></em></strong></span>
        </p>
        <p class="p1">
          <span class="s1">在多數情況下，你可選擇是否同意在本網站使用</span><span class="s2">Cookies</span><span
          class="s1">。然而，若是為了提供你要求的服務，或為了運作本網站固有功能等重要情況下而默認使用</span><span class="s2">Cookies</span><span
          class="s1">，則為例外。</span>
        </p>
        <p class="p1">
          <span class="s1">初次瀏覽本網站時，頁面上方將跳出訊息告知本網站的</span><span class="s2">Cookies</span><span
          class="s1">使用，如果你持續使用本網站，代表你同意我們運用</span><span class="s2">Cookies</span><span
          class="s1">與其他數據追蹤工具。若你不接受，可隨時更改</span><span class="s2">Cookies</span><span
          class="s1">設定。如欲了解更多資訊，請參考下方「如何管理設定」。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <strong>如何管理設定</strong><br/>
        </p>
        <p class="p1">
          <strong><br/></strong>
        </p>
        <p class="p1">
          <span class="s1">你可隨時於瀏覽器設定中更改</span><span class="s2">Cookies</span><span class="s1">的應用，然而，如果你不接受</span><span
          class="s2">Cookies</span><span class="s1">，你可能無法享受本網站完整的功能或服務。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">如欲調整設定，可在瀏覽器偏好設定或設定選項中修改，例如：</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Chrome</span><span class="s4">：設定</span><span class="s6"> </span><span
          class="s1">-- </span><span class="s4">進階</span><span class="s6"> </span><span class="s1">-- </span><span
          class="s4">隱私權和安全性</span><span class="s6"> </span><span class="s1">-- Cookies</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Safari</span><span class="s4">：偏好設定</span><span class="s6"> </span><span
          class="s1">-- </span><span class="s4">隱私權</span><span class="s6"> </span><span
          class="s1">-- Cookies</span><span class="s4">和網站資料</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Firefox</span><span class="s4">：偏好設定</span><span class="s6"> </span><span
          class="s1">-- </span><span class="s4">隱私與安全性</span><span class="s6"> </span><span class="s1">-- Cookies</span><span
          class="s4">和網站資料</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Internet Explorer</span><span class="s4">：網際網路選項</span><span class="s6"> </span><span
          class="s1">-- </span><span class="s4">隱私權</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Opera</span><span class="s4">：設定</span><span class="s6"> </span><span
          class="s1">-- </span><span class="s4">私人資料</span><span class="s6"> </span><span class="s1">-- Cookies</span>
        </p>
        <p>
          <br/>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'CookieHk'
  };
</script>
<style scoped lang="less">
  table{
    td{padding:8px;}
  }
</style>
