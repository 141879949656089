<template>
  <div>
    <div class="h1">退款政策</div>
    <div class="layout">
      <div class="rich-text">
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>

        <p>
          <strong><span style="font-size: 18px;">1.1 訂單確認</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          1.1.1 部分指定活動或服務接受即時確認。除此類活動或服務外，任何在本網站所述的確認時間僅供參考。實際所需的確認時間，會因應情況而有所調整。
        </p>
        <p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.2 購買及使用憑證</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1.2.1 透過本網站，你可訂購由目的地供應商所提供的服務的電子憑證（下稱「憑證」）。在相關供應商的條款限制下，你將收到一封確認你訂單的電子郵件，其中包含憑證的確認號碼（下稱「確認號碼」），以及可列印的版本。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.2.2 使用你的憑證時，你須準時到達相關供應商指定的集合地點，且提供該供應商要求的文件及/或資料，例如你的預訂確認號碼及/或列印的憑證。若你無法準時出現，或未能提供必要的文件或資料，你將不會享受該服務或退款。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.2.3 供應商可能要求你提供載有你照片的身份文件，才准許你使用憑證。若你遺失憑證、或憑證被破壞、遭偷竊，一遊 UNU TRAVEL或供應商均無需負上責任。此外，若法律禁止提供相關服務，憑證將失效。若你試圖以不合法的形式兌換憑證（比方說你欲參加品酒活動，但未達能使用該服務的規定年齡），相關供應商將可拒絕接受你的憑證，且無需安排退款。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.3 憑證之《使用條款》</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.3.2 憑證是一次項目（下稱「項目」）的入場券；憑證上會載明可使用的期限。除非憑證上另有明白規定，否則若你未能在憑證上載明的期限內使用憑證，將不獲退款。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.4 取消憑證</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1.4.1 你可在訂購時在網站上載明的取消期限內，聯絡一遊 UNU TRAVEL的顧客服務，取消你的憑證。取消的聯絡窗口因個案而異。在成功取消使用憑證且收到必要的通知後，金額將會全數退款至你用來訂購該憑證的付款帳戶中。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1.4.2 提供服務該項目的是供應商，而非一遊 UNU TRAVEL，因此該項目的供應商應獨自接受或拒絕你訂購的任何憑證，並負責提供所有相關的服務。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.4.3 若你對該項憑證的服務有任何疑問或投訴，請向相關供應商查詢。所有憑證已支付的費用均不可退款，除非本《使用條款》明確規定。憑證是以訂購當日在網站上載明的貨幣來報價。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.4.4 若你所訂購的項目被相關供應商取消，一遊 UNU TRAVEL會盡快以合理的方法通知你，並會將金額全數退款到你用來訂購該憑證的付款帳戶中。</span>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.5 要求協助</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.5.1 若你試圖根據本《使用條款》及適用於該憑證的相關使用條款來兌換使用憑證，但你無法成功使用（因為供應商的錯誤或其他原因），請透過電子郵箱support@unutravel.com聯絡一遊 UNU TRAVEL，一遊 UNU TRAVEL將嘗試接觸並與供應商聯繫以尋求協商。
        </p>
        <p class="p1">
          <br/>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.6 額外收費</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.6.1 一遊 UNU TRAVEL保留權利以為所提供的任何服務向你提出付款要求或收費。你應依照本網站的說明，支付你選擇的服務所需的所有適用的費用。
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.7 收費更改</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.7.1 一遊 UNU TRAVEL保留隨時變更價格及收費的權利，變更的內容可能透過電子郵件或張貼在網站，若你於該等變更的通知發出後使用，或繼續使用該服務，意味閣下同意並接納變更後的價格及收。
        </p>
        <p class="p1">
          <br/>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.8 一遊 UNU TRAVEL的權利及義務</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.8.1 一遊 UNU TRAVEL保留絕對酌情權以拒絕或取消任何可能違反此條款的服務訂單或訂購。
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.8.2 一遊 UNU TRAVEL致力提供或向供應商爭取以市場上的最優價值提供服務。你確認了解及認同任何於使用個別服務時所產生的稅項或額外費用，皆明確地排除在最優價格計算之外。
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;"><span
          class="s1">1.8.3 儘管供應商被要求向一遊 UNU TRAVEL網站上之服務提供準確及最新的價格，一遊 UNU TRAVEL無法保證所提供的服務於任何時候皆準確無誤。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.9 以上條款及細則及退款政策適用於全球的一遊 UNU TRAVEL用戶。</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
          <br/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'RefundHk'
  };
</script>
<style scoped>
</style>
