<template>
  <div>
    <div class="h1">私隐政策及免责声明</div>
    <div class="layout">
      <div class="rich-text">
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">本政策旨在解释又一科技有限公司</span><span class="s2"> UNU TECHNOLOGY LIMITED</span><span
          class="s1">在网站（</span><span class="s2">www.unutravel.com</span><span
          class="s1">）收集、保留、储存及分享个人资料方面的做法。又一科技有限公司</span><span class="s2"> UNU TECHNOLOGY LIMITED</span><span
          class="s1">有权修改本「私隐政策」，而有关更新的版本将登载于本网页上。</span><br/>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">&nbsp;</span>
        </p>
        <p>
          <span style="font-size: 18px;"><strong>A.&nbsp;私隐条款</strong></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">又一科技有限公司</span><span class="s2"> UNU TECHNOLOGY LIMITED</span><span
          class="s1">（「本公司」），包括「本公司」辖下网站（「一游</span><span class="s2">UNU TRAVEL</span><span class="s1">网站」，官方网址为<a
          href="http://www.unutravel.com"><span class="s3">www.unutravel.com</span></a>），均尊重向本公司属下网站提供个人资料的人士之私隐权，并遵守香港特别行政区法例第</span><span
          class="s2">486</span><span class="s1">章个人资料（私隐）条例的规定。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">1. </span><span class="s1">本公司从「一游</span><span class="s2">UNU TRAVEL</span><span
          class="s1">网站」所收集的个人资料（定义见个人资料（私隐）条例），包括以下各项内容：</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">浏览者个人计算机网络协议地址（</span><span class="s2">IP Address</span><span
          class="s1">）及地域等，曾浏览「一游</span><span class="s2">UNU TRAVEL</span><span class="s1">网站」的时间、浏览器类别及曾浏览的网页；</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">有些个人资料可能会在互联网上经「曲奇档案」（</span><span class="s2">Cookies</span><span
          class="s1">）收集。「曲奇档案」（</span><span class="s2">Cookies</span><span
          class="s1">）是个人的计算机互联网浏览器自动存档在某个网站的零碎数据，可经该网站存取。「曲奇档案」（</span><span class="s2">Cookies</span><span
          class="s1">）收集的是不记名的集体统计资料，并不包括姓名、地址、电话、及电邮地址等个人联络数据。大多数互联网浏览器都会自动采用「曲奇档案」（</span><span
          class="s2">Cookies</span><span class="s1">），阁下亦可以编辑浏览器选项封锁此功能，然而，阁下可能因此而不能进入「一游</span><span
          class="s2">UNU TRAVEL</span><span class="s1">网站」的某些网页。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">假如浏览者透过使用「一游</span><span class="s2">UNU TRAVEL</span><span
          class="s1">网站」的服务，而与本公司达成协议，成为「一游</span><span class="s2">UNU TRAVEL</span><span
          class="s1">网站」的会员，于登记时透过填写登记表格向本公司提供的数据，包括但不限于浏览者的姓名、年龄、性别、电邮地址及于登记表格内的其他数据；及其他由浏览者自愿向本公司提供的个人资料。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">2. </span><span
          class="s1">本公司于网上收集的客户个人资料会用作本公司向阁下提供服务、处理查询及投诉、做服务调查的用途，阁下的个人资料亦会在阁下的同意下有机会作为本公司直接推销的用途。若阁下未满</span><span
          class="s2">13</span><span
          class="s1">岁，阁下必须在向本公司提供任何个人资料之前征询家长或阁下的监护人的同意。此外阁下的个人资料亦可能按所需情况就下列目的及用途而转移或转让或披露予下述（包括但不限于）各方：</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">申请、日常运作（包括但不限于会员</span><span class="s2">/</span><span
          class="s1">使用者更新）、延续使用及终止使用本网站的服务；</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">于一游</span><span class="s2">UNU TRAVEL</span><span class="s1">网站刊登广告的客户；</span>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          本公司的代理商、供货商、承包商、合作伙件或任何其他服务提供商，以供其用于本网站提供的服务或相关的用途；
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          核实阁下身份；
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          按对本公司有约束力的任何法例要求，向任何香港政府机关披露个人资料：及内部统计调查和分析。
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">3. </span><span
          class="s1">若阁下不愿意本公司使用阁下的个人资料，阁下可自愿决定是否提供没有被标示为必须的基本个人资料，阁下可自愿提供阁下的个人资料所涉及的目的是：</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">使本公司更能够了解本「一游</span><span class="s2">UNU TRAVEL</span><span class="s1">网站」会员或用户的特征；及</span>使本公司能够提供更切合阁下所需的相关信息、产品或其他服务
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">4. </span><span class="s1">本公司在收集阁下的个人资料时，将会：</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          向阁下说明本公司正在收集阁下的个人资料以及收集的用途；
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          给阁下反对使用个人资料作特定用途的机会：
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          及说明本公司如何储存个人资料的方法，以及可供阁下查阅、更改及删除已储存个人资料的方法。
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">5. </span><span
          class="s1">本公司拟使用阁下所提供的个人资料作直接促销；除非得到阁下同意（包括阁下不反对的表示），否则本公司不会使用阁下的个人资料。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">6. </span><span
          class="s1">本公司内部设有员工守则，务求确保员工不会将阁下数据使用于上述指定的用途以外，并提醒员工必须将阁下的个人资料保密。阁下的个人资料不论如何储存，将只限于本公司已获授权查阅该等数据的员工方可查阅。凡以电子方式储存的个人资料，将以加密保障，以及只限「一游</span><span
          class="s2">UNU TRAVEL</span><span class="s1">网站」的获授权人员方可查阅。被指定负责处理个人资料的员工，只在按照本收集个人资料声明的规定下方会被指示进行相关处理。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">7. </span><span
          class="s1">若基于任何理由（包括向阁下追讨阁下欠本公司的任何款项），本公司有必要在法律程序中对阁下采取行动时，阁下明示同意，在识别阁下身份及对阁下采取法律行动时，可以在阁下所提供的个人资料中作为依据。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">8. </span><span
          class="s1">本公司在为贯彻阁下的个人资料被收集的目的所需的时间内保留阁下的个人资料。本公司亦可为统计目的而保留个人资料档案。直至该等资料已不再存有任何直接或有关的用途后，便会删除或销毁该等个人资料。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">9. </span><span class="s1">阁下有权向本公司书面查询本公司是否拥有阁下的个人资料，以及要求取得该等数据的副本及</span><span
          class="s2">/</span><span class="s1">或更正该等资料。如阁下希望作出上述要求，请联络本公司（<a href="mailto:info@tvmost.com.hk"><span
          class="s5">info@</span></a></span><span class="s5"> unutravel.com</span><span
          class="s1">）提出上述要求，本公司在接获此等要求后，将尽快（在不多于四十天内）处理。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">10. </span><span class="s1">本私隐政策有可能不时被本公司更新而不另行通知阁下。阁下可以随时到以下的网页（<a
          href="https://www.unutravel.com/about/privacy"><span class="s3">https://www.unutravel.com/about/privacy</span></a>），浏览本私隐政策的最新版本。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p>
          <span style="font-size: 18px;"><strong>B.&nbsp;免责声明</strong></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">1. </span><span class="s1">本网站所载信息是为「一游</span><span class="s2">UNU TRAVEL</span><span
          class="s1">网站」其公司所提供的各项服务而准备及制作，本公司对其所载的信息完整性及准确性不作任向保证。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">2. </span><span class="s1">本公司会尽力将储存在「一游</span><span class="s2">UNU TRAVEL</span><span
          class="s1">网站」系统内的个人资料保密，免受到不必要的盗取及使用，但鉴于互联网的性质，本公司不能对数据传送安全和保密作出完全保证或声明。若阁下发现个人资料在未经授权下遭泄漏，请立即与本公司联络。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">3. </span><span class="s1">与「一游</span><span class="s2">UNU TRAVEL</span><span
          class="s1">网站」连结的其他网站，也可能搜集您个人的资料。「一游</span><span class="s2">UNU TRAVEL</span><span
          class="s1">网站」的私隐条款将不适用于浏览者从「一游</span><span class="s2">UNUTRAVE </span><span
          class="s1">网站」连结到其他网站及继续阅读其他网站时被收集个人资料，您须自行承担有关之风险，本公司对于连结其他网站上所载信息的准确性、有效性、安全性或如有任何遗漏，均不承担任何责任。</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PrivacyCn'
  };
</script>
