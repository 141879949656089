<template>
  <div>
    <div class="h1">私隱政策及免責聲明</div>
    <div class="layout">
      <div class="rich-text">
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">本政策旨在解釋又一科技有限公司</span><span class="s2"> </span><span class="s3">UNU TECHNOLOGY LIMITED</span><span
          class="s1">在網站（</span><span class="s3">www.unutravel.com</span><span
          class="s1">）收集、保留、儲存及分享個人資料方面的做法。又一科技有限公司</span><span class="s2"> </span><span
          class="s3">UNU TECHNOLOGY LIMITED</span><span class="s1">有權修改本「私隱政策」，而有關更新的版本將登載於本網頁上。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">&nbsp;</span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">A.&nbsp;私隱條款</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">又一科技有限公司</span><span class="s2"> </span><span class="s3">UNU TECHNOLOGY LIMITED</span><span
          class="s1">（「本公司」），包括「本公司」轄下網站（「一遊</span><span class="s3">UNU TRAVEL</span><span class="s1">網站」，官方網址為<a
          href="http://www.unutravel.com"><span class="s4">www.unutravel.com</span></a>），均尊重向本公司屬下網站提供個人資料的人士之私隱權，並遵守香港特別行政區法例第</span><span
          class="s3">486</span><span class="s1">章個人資料（私隱）條例的規定。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1. </span><span class="s1">本公司從「一遊</span><span class="s3">UNU TRAVEL</span><span
          class="s1">網站」所收集的個人資料（定義見個人資料（私隱）條例），包括以下各項內容：</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">瀏覽者個人電腦網絡協定位址（</span><span class="s3">IP Address</span><span
          class="s1">）及地域等，曾瀏覽「一遊</span><span class="s3">UNU TRAVEL</span><span class="s1">網站」的時間、瀏覽器類別及曾瀏覽的網頁；</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">有些個人資料可能會在互聯網上經「曲奇檔案」（</span><span class="s3">Cookies</span><span
          class="s1">）收集。「曲奇檔案」（</span><span class="s3">Cookies</span><span
          class="s1">）是個人的電腦互聯網瀏覽器自動封存在某個網站的零碎資料，可經該網站存取。「曲奇檔案」（</span><span class="s3">Cookies</span><span
          class="s1">）收集的是不記名的集體統計資料，並不包括姓名、地址、電話、及電郵地址等個人聯絡資料。大多數互聯網瀏覽器都會自動採用「曲奇檔案」（</span><span
          class="s3">Cookies</span><span class="s1">），閣下亦可以編輯瀏覽器選項封鎖此功能，然而，閣下可能因此而不能進入「一遊</span><span
          class="s3">UNU TRAVEL</span><span class="s1">網站」的某些網頁。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">假如瀏覽者透過使用「一遊</span><span class="s3">UNU TRAVEL</span><span
          class="s1">網站」的服務，而與本公司達成協議，成為「一遊</span><span class="s3">UNU TRAVEL</span><span
          class="s1">網站」的會員，於登記時透過填寫登記表格向本公司提供的資料，包括但不限於瀏覽者的姓名、年齡、性別、電郵地址及於登記表格內的其他資料；及其他由瀏覽者自願向本公司提供的個人資料。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">2. </span><span
          class="s1">本公司於網上收集的客戶個人資料會用作本公司向閣下提供服務、處理查詢及投訴、做服務調查的用途，閣下的個人資料亦會在閣下的同意下有機會作為本公司直接推銷的用途。若閣下未滿</span><span
          class="s3">13</span><span
          class="s1">歲，閣下必須在向本公司提供任何個人資料之前徵詢家長或閣下的監護人的同意。此外閣下的個人資料亦可能按所需情況就下列目的及用途而轉移或轉讓或披露予下述（包括但不限於）各方：</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">申請、日常運作（包括但不限於會員</span><span class="s3">/</span><span
          class="s1">使用者更新）、延續使用及終止使用本網站的服務；</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">於一遊</span><span class="s3">UNU TRAVEL</span><span class="s1">網站刊登廣告的客戶；</span>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          本公司的代理商、供應商、承包商、合作夥件或任何其他服務提供者，以供其用於本網站提供的服務或相關的用途；
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          核實閣下身份；
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          按對本公司有約束力的任何法例要求，向任何香港政府機關披露個人資料：及內部統計調查和分析。
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">3. </span><span
          class="s1">若閣下不願意本公司使用閣下的個人資料，閣下可自願決定是否提供沒有被標示為必須的基本個人資料，閣下可自願提供閣下的個人資料所涉及的目的是：</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">使本公司更能夠了解本「一遊</span><span class="s3">UNU TRAVEL</span><span class="s1">網站」會員或使用者的特徵；及</span>使本公司能夠提供更切合閣下所需的相關資訊、產品或其他服務
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">4. </span><span class="s1">本公司在收集閣下的個人資料時，將會：</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          向閣下說明本公司正在收集閣下的個人資料以及收集的用途；
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          給閣下反對使用個人資料作特定用途的機會：及說明本公司如何儲存個人資料的方法，以及可供閣下查閱、更改及刪除已儲存個人資料的方法。
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">5. </span><span
          class="s1">本公司擬使用閣下所提供的個人資料作直接促銷；除非得到閣下同意（包括閣下不反對的表示），否則本公司不會使用閣下的個人資料。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">6. </span><span
          class="s1">本公司內部設有員工守則，務求確保員工不會將閣下資料使用於上述指定的用途以外，並提醒員工必須將閣下的個人資料保密。閣下的個人資料不論如何儲存，將只限於本公司已獲授權查閱該等資料的員工方可查閱。凡以電子方式儲存的個人資料，將以加密保障，以及只限「一遊</span><span
          class="s3">UNU TRAVEL</span><span class="s1">網站」的獲授權人員方可查閱。被指定負責處理個人資料的員工，只在按照本收集個人資料聲明的規定下方會被指示進行相關處理。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">7. </span><span
          class="s1">若基於任何理由（包括向閣下追討閣下欠本公司的任何款項），本公司有必要在法律程序中對閣下採取行動時，閣下明示同意，在識別閣下身份及對閣下採取法律行動時，可以在閣下所提供的個人資料中作為依據。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">8. </span><span
          class="s1">本公司在為貫徹閣下的個人資料被收集的目的所需的時間內保留閣下的個人資料。本公司亦可為統計目的而保留個人資料檔案。直至該等資料已不再存有任何直接或有關的用途後，便會刪除或銷毀該等個人資料。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">9. </span><span class="s1">閣下有權向本公司書面查詢本公司是否擁有閣下的個人資料，以及要求取得該等資料的副本及</span><span
          class="s3">/</span><span class="s1">或更正該等資料。如閣下希望作出上述要求，請聯絡本公司（<a href="mailto:info@tvmost.com.hk"><span
          class="s6">info@</span></a></span><span class="s6"> unutravel.com</span><span
          class="s1">）提出上述要求，本公司在接獲此等要求後，將盡快（在不多於四十天內）處理。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">10. </span><span class="s1">本私隱政策有可能不時被本公司更新而不另行通知閣下。閣下可以隨時到以下的網頁（<a
          href="https://www.unutravel.com/about/privacy"><span class="s4">https://www.unutravel.com/about/privacy</span></a>），瀏覽本私隱政策的最新版本。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">&nbsp;</span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">B.&nbsp;免責聲明</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1. </span><span class="s1">本網站所載資訊是為「一遊</span><span class="s3">UNU TRAVEL</span><span
          class="s1">網站」其公司所提供的各項服務而準備及製作，本公司對其所載的資訊完整性及準確性不作任向保證。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">2. </span><span class="s1">本公司會盡力將儲存在「一遊</span><span class="s3">UNU TRAVEL</span><span
          class="s1">網站」系統內的個人資料保密，免受到不必要的盜取及使用，但鑑於互聯網的性質，本公司不能對資料傳送安全和保密作出完全保證或聲明。若閣下發現個人資料在未經授權下遭洩漏，請立即與本公司聯絡。</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">3. </span><span class="s1">與「一遊</span><span class="s3">UNU TRAVEL</span><span
          class="s1">網站」連結的其他網站，也可能蒐集您個人的資料。「一遊</span><span class="s3">UNU TRAVEL</span><span
          class="s1">網站」的私隱條款將不適用於瀏覽者從「一遊</span><span class="s3">UNUTRAVE </span><span
          class="s1">網站」連結到其他網站及繼續閱讀其他網站時被收集個人資料，您須自行承擔有關之風險，本公司對於連結其他網站上所載資訊的準確性、有效性、安全性或如有任何遺漏，均不承擔任何責任。</span>
        </p>
        <p>
          <br/>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PrivacyHk'
  };
</script>
<style scoped>
</style>
