<template>
  <div>
    <div class="h1">{{$t('aboutus')}}</div>
    <div class="layout rich-text">
      <ul style="padding:100px 0;list-style-type:disc;line-height:2em;font-size:16px;">
        <li style="padding:5px 0;">
          UNU Travel, a flagship product of Hong Kong UNU Technology Limited, committed to building the future tourism ecosystem. Taking the lead in proposing and realizing the five-in-one international cultural tourism platform economic model of ”Platform Technology + Cultural Tourism + Parent-Child Study Tour + New Media Value + Sharing Economy".
        </li>
        <li style="padding:5px 0;">
          UNU Travel provides one-stop business solutions and marketing strategies for the travel industry. The travel industry can upgrade and transform at zero cost and build its own online travel agency at zero cost. A complete tourism business management and operation system greatly reduce the industry's operating costs and improves efficiency. And help the industry free up manpower and resource to develop more products/services that meet the needs of travelers.
        </li>
        <li style="padding:5px 0;">
          Through UNU Travel, the tour guide leader will have greater autonomy, and can take the group they want to bring, and walk the route they want to take.
        </li>
        <li style="padding:5px 0;">
          Through UNU Travel, travelers are not just consumers, they can achieve their dream of making money while traveling.
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AboutUsEn'
  };
</script>
