<template>
  <div>
    <div class="h1">Cookie政策</div>
    <div class="layout">
      <div class="rich-text">
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">一游</span><span class="s2">UNU TRAVEL</span><span
          class="s1">致力改善你在旅途中使用本网页的体验。我们运用</span><span class="s2">cookie</span><span
          class="s1">管理网页务求为你带来更佳的网页使用经验。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">我们将极力保护你的个人隐私。如欲了解更多信息，请参考</span><span class="s3">私隐政策及免责声明</span><span class="s1">。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p2">
          <span class="s4">以下说明一游</span><span class="s1">UNU TRAVEL</span><span class="s4">运用</span><span
          class="s1">cookie</span><span class="s4">的基本规范。</span>
        </p>
        <p class="p2">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p2">
          <span class="s1"><strong>cookie</strong></span><span class="s4"><strong>是什么？</strong></span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">Cookie</span><span class="s1">是储存于你浏览器的小型文本文件，多数网络都有使用，用于储存你的网络使用，例如登入数据与浏览记录。</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">Cookie</span><span class="s1">可根据</span><span class="s2">(i)</span><span
          class="s1">时效与</span><span class="s2">(ii)</span><span class="s1">其设置机构分类：</span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em><br/></em></strong></span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em>(i)</em></strong></span><span class="s4"><strong>暂时性</strong></span><span
          class="s1"><strong><em>Cookies</em></strong></span><span class="s4"><strong>与持久性</strong></span><span
          class="s1"><strong><em>Cookie</em></strong></span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">Cookies</span><span class="s1">时效取决于他们是暂时性或持久性。</span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>暂时性</strong></span><span class="s2"><strong>Cookies</strong></span><span
          class="s1">会在你关闭浏览器后自动删除。</span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>持久性</strong></span><span class="s2"><strong>Cookies</strong></span><span
          class="s1">会在用户的计算机或手机设备上储存一段固定时间。当你浏览网站时，浏览器会重新取回这些</span><span class="s2">Cookies</span><span
          class="s1">，方便网站识别你的使用设备，并根据你前次的浏览记录，提供个人化的网站体验。</span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em><br/></em></strong></span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em>(ii)</em></strong></span><span class="s4"><strong>第一方及第三方</strong></span><span
          class="s1"><strong><em>Cookies</em></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">我们控管<strong>第一方</strong></span><span class="s2"><strong>Cookies</strong></span><span
          class="s1">的运用范围，但第三方</span><span class="s2">Cookies</span><span
          class="s1">由第三方网站操作，我们无控制权限。本网站的部分页面包含嵌入式内容形式的第三方</span><span class="s2">Cookies</span><span
          class="s1">，例如</span><span class="s2">JavaScript</span><span class="s1">和</span><span
          class="s2">API</span><span class="s1">。</span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>为什么我们要使用</strong></span><span
          class="s2"><strong><em>Cookies</em></strong></span><span class="s1"><strong>？</strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">为了确保安全、有效率且个人化的服务，我们将</span><span class="s2">Cookies</span><span class="s1">技术应用于以下用途：</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">确保网站功能有效运作；</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">检视访客如何进入及使用网站；</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">分析旅客喜爱的热门旅游地点；</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">了解你对推广与宣传活动的兴趣；</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">推荐你会感兴趣的旅游地点；以及</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">改善网站内容与使用者体验</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">除了以上用途外，我们保证绝不将</span><span class="s2">Cookies</span><span class="s1">用于其他目的。</span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>我们使用哪种</strong></span><span class="s2"><strong><em>Cookies</em></strong></span><span
          class="s1"><strong>类型？</strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">我们使用的</span><span class="s2">Cookies</span><span class="s1">共有四种类型，「绝对必要</span><span
          class="s2">Cookies</span><span class="s1">」、「必要</span><span class="s2">Cookies</span><span
          class="s1">」、「分析</span><span class="s2">Cookies</span><span class="s1">」、「广告</span><span
          class="s2">Cookies</span><span class="s1">」。如欲了解更多信息，请查看下方表格：</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <table class="t1">
          <tbody>
          <tr class="firstRow">
            <td valign="middle" class="td1" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s1"><strong>Cookies</strong></span>
              </p>
            </td>
            <td valign="middle" class="td2" style="border-width: 1px; border-style: solid;">
              <p class="p3">
                <span class="s4"><strong>说明</strong></span>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="td3" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s4">绝对必要</span><span class="s1">Cookies</span>
              </p>
            </td>
            <td valign="middle" class="td4" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1">此类型的</span><span class="s2">Cookies</span><span
                class="s1">能协助我们确保网站功能运作正常，同时提供你导览本网站的服务、更便利的使用体验。</span><span class="s2"><br/></span><span
                class="s1">如果你停用或封锁此类型</span><span class="s2">Cookies</span><span
                class="s1">，我们将无法提供你本网站的服务与部分功能。</span><span class="s2"><br/></span><span class="s1">我们也将此类型</span><span
                class="s2">Cookies</span><span class="s1">应用于诈欺侦测与预防等用途。此外，透过此类型</span><span
                class="s2">Cookies</span><span class="s1">，使用者登入后，浏览本网站不同页面时便无须再次登入。</span>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="td5" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s4">分析</span><span class="s1">Cookies</span>
              </p>
            </td>
            <td valign="middle" class="td6" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1">我们使用</span><span class="s2">Google Analytics</span><span
                class="s1">和其他分析工具，搜集以下匿名统计资料：</span><span class="s2"><br/>•&nbsp;</span><span
                class="s1">评估访客如何使用本网站；</span><span class="s2"><br/>•&nbsp;</span><span class="s1">了解访客基本信息与所在位置；</span><span
                class="s2"><br/>•&nbsp;</span><span class="s1">评估用户如何接收我们的新产品，检视营销活动成效；</span><span class="s2"><br/>•&nbsp;</span><span
                class="s1">提供个人化网站，改善网站内容与服务。</span><span class="s2"><br/></span><span
                class="s1">依据搜集的匿名数据数据，此类型分析工具能协助汇整趋势分析与报告纪录，以达到上述用途。</span>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="td7" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s4">广告</span><span class="s1">Cookies</span>
              </p>
            </td>
            <td valign="middle" class="td8" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1">此类型</span><span class="s2">Cookies</span><span class="s1">能识别你的</span><span
                class="s2">IP</span><span
                class="s1">地址，追踪并记忆你在不同网站上的使用习惯与喜好，以便提供与你相关、兴趣相符的广告内容，并减少你在本网站上看到相同广告的次数。</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
        <p class="p2">
          <span class="s4"><strong><br/></strong></span>
        </p>
        <p class="p2">
          <span class="s4"><strong>同意接受</strong></span><span class="s1"><strong><em>Cookies</em></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">在多数情况下，你可选择是否同意在本网站使用</span><span class="s2">Cookies</span><span
          class="s1">。然而，若是为了提供你要求的服务，或为了运作本网站固有功能等重要情况下而默认使用</span><span class="s2">Cookies</span><span
          class="s1">，则为例外。</span>
        </p>
        <p class="p1">
          <span class="s1">初次浏览本网站时，页面上方将跳出讯息告知本网站的</span><span class="s2">Cookies</span><span
          class="s1">使用，如果你持续使用本网站，代表你同意我们运用</span><span class="s2">Cookies</span><span
          class="s1">与其他数据追踪工具。若你不接受，可随时更改</span><span class="s2">Cookies</span><span
          class="s1">设定。如欲了解更多信息，请参考下方「如何管理设定」。</span>
        </p>
        <p class="p3">
          <span class="s4"><strong><br/></strong></span>
        </p>
        <p class="p3">
          <span class="s4"><strong>如何管理设定</strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">你可随时于浏览器设定中更改</span><span class="s2">Cookies</span><span class="s1">的应用，然而，如果你不接受</span><span
          class="s2">Cookies</span><span class="s1">，你可能无法享受本网站完整的功能或服务。</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">如欲调整设定，可在浏览器偏好设定或设定选项中修改，例如：</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Chrome</span><span class="s4">：设定</span><span class="s1"> -- </span><span
          class="s4">进阶</span><span class="s1"> -- </span><span class="s4">隐私权和安全性</span><span
          class="s1"> -- Cookies</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Safari</span><span class="s4">：偏好设定</span><span class="s1"> -- </span><span
          class="s4">隐私权</span><span class="s1"> -- Cookies</span><span class="s4">和网站数据</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Firefox</span><span class="s4">：偏好设定</span><span class="s1"> -- </span><span
          class="s4">隐私与安全性</span><span class="s1"> -- Cookies</span><span class="s4">和网站数据</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Internet Explorer</span><span class="s4">：因特网选项</span><span
          class="s1"> -- </span><span class="s4">隐私权</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Opera</span><span class="s4">：设定</span><span class="s1"> -- </span><span
          class="s4">私人数据</span><span class="s1"> -- Cookies</span>
        </p>
        <p>
          <br/>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'CookieCn'
  };
</script>
<style scoped lang="less">
  table{
    td{padding:8px;}
  }
</style>
