<template>
  <div>
    <div class="h1">Cookie Policy</div>
    <div class="layout">
      <div class="rich-text">
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">At UNU TRAVEL, we strive to enhance your experience during your trip and using this Website. In general, we use cookies to manage the Website and improve your overall site experience.
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">Your data privacy and confidentiality are our top priority and we do our very best to safeguard the information you entrusted to us. For more information, you can review our Privacy Notice here.
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p2">
          <span class="s4">This section about cookies sets out the basis and terms upon which UNU TRAVEL uses cookies.</span>
        </p>
        <p class="p2">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p2">
          <span class="s1"><strong>What are cookies?
</strong></span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">Cookie</span><span class="s1">Cookies are widely used in the Internet. They are small text files stored in your browser that contain basic information about your internet use, such as login details and your previous browsing records.
</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">A cookie can be classified in terms of its (i) duration and (ii) the party who places it:
</span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em><br/></em></strong></span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em>(i)</em></strong></span><span class="s4"><strong>Session </strong></span><span
          class="s1"><strong><em>Cookies and </em></strong></span><span class="s4"><strong>Persistent </strong></span><span
          class="s1"><strong><em>Cookie</em></strong></span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">The duration of cookies depends on whether they are session or persistent cookies.</span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>Session </strong></span><span class="s2"><strong>Cookies </strong></span><span
          class="s1">expire once the browsing session ends.</span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>Persistent </strong></span><span class="s2"><strong>Cookies </strong></span><span
          class="s1">are saved on your computer or mobile device for a fixed period of time. When you visit a website, your browser will retrieve these cookies so that the website will recognise your device and personalise your site experience according to your previous browsing records.</span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em><br/></em></strong></span>
        </p>
        <p class="p2">
          <span class="s1"><strong><em>(ii)</em></strong></span><span class="s4"><strong>First-party and Third-party </strong></span><span
          class="s1"><strong><em>Cookies</em></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">We control and set the scope of first-party cookies whereas, we have no control over third-party cookies. Third-party cookies are operated by third-party sites in a different domain. Some pages of this Website contain third-party cookies in the form of embedded content, such as JavaScript and API.
</span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>Why do we use cookies?</strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">We are committed to providing you with a safe, efficient and customised service. To reach this goal, we use cookies to:</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">ensure the efficient functioning of this Website;</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">evaluate how this Website is accessed and used;</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">analyze what destinations are popular among visitors;
</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">determine your eligibility for campaigns and promotions;
</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1">recommend the places that match your interests; and
</span>
        </p>
        <p class="p1">
          <span class="s2"><br/></span>
        </p>
        <p class="p1">
          <span class="s2">•&nbsp;</span><span class="s1"> improve the content of this Website and enhance user's experience.
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">We will ensure that cookies will not be used for any purpose other than those described here.
</span>
        </p>
        <p class="p1">
          <span class="s1"><strong><br/></strong></span>
        </p>
        <p class="p1">
          <span class="s1"><strong>What types of cookies do we use?</strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">We use four types of cookies, namely "Strictly necessary cookies", "Essential cookies," "Analytics cookies", and "Advertising cookies". Further information on these cookies is set out in the table below:
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <table class="t1">
          <tbody>
          <tr class="firstRow">
            <td valign="middle" class="td1" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s1"><strong>Cookies</strong></span>
              </p>
            </td>
            <td valign="middle" class="td2" style="border-width: 1px; border-style: solid;">
              <p class="p3">
                <span class="s4"><strong>Description
</strong></span>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="td3" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s4">Strictly necessary cookies
</span>
              </p>
            </td>
            <td valign="middle" class="td4" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1">These cookies allow us to manage this Website and ensure it functions properly. They are essential to help you navigate around this Website and provide you with an improved surfing experience.
</span><span class="s2"><br/></span><span
                class="s1">If these cookies are disabled, we will not be in a position to offer you our services.
</span><span
                class="s2">Cookies</span><span class="s1">We also use these cookies for fraud detection and prevention purposes. </span><span
                class="s2">Cookies</span><span class="s1">Further, thanks to these cookies, you do not need to log in repeatedly to access different pages once you have logged into this Website.</span>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="td5" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s4">Analytics cookies</span>
              </p>
            </td>
            <td valign="middle" class="td6" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1">We use analytics cookies including Google Analytics and other analytics tools to gather anonymous statistical information for:
</span><span class="s2"><br/>•&nbsp;</span><span
                class="s1">assessing how our visitors use this Website;
</span><span class="s2"><br/>•&nbsp;</span><span class="s1">understanding the demographic and location of our visitors;
</span><span
                class="s2"><br/>•&nbsp;</span><span class="s1">evaluating how our new products are being received by usersassessing the popularity of our marketing campaign;
</span><span class="s2"><br/>•&nbsp;</span><span
                class="s1">enhancing this Website content and service by customising this Website for you.
</span><span class="s2"><br/></span><span
                class="s1">Based on the anonymous data collected, these analytics tools will generate trend analysis and compile reports to attain the abovementioned purposes.
</span>
              </p>
            </td>
          </tr>
          <tr>
            <td valign="middle" class="td7" style="border-width: 1px; border-style: solid;">
              <p class="p2">
                <span class="s4">Advertising cookies
</span>
              </p>
            </td>
            <td valign="middle" class="td8" style="border-width: 1px; border-style: solid;">
              <p class="p1">
                <span class="s1">Advertising cookies enable us to limit the number of times you see the same ad and to ensure that the ad content is relevant to your interests. These cookies, together with an identifier linked to your IP address, can track your activity across various websites and thus display ads in accordance to your preference.
</span>
              </p>
            </td>
          </tr>
          </tbody>
        </table>
        <p class="p2">
          <span class="s4"><strong><br/></strong></span>
        </p>
        <p class="p2">
          <span class="s4"><strong>Consent to cookies
</strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">In most cases we will need your consent in order to use cookies on this Website. The exception is where the cookies are strictly necessary in order for us to provide you with a service you have requested, or essential to the inherent functionality of the Website.
</span>
        </p>
        <p class="p1">
          <span class="s1">Upon your first visit to our Website, you will see a notification banner at the top of the page notifying you about our use of cookies. By continuing to use this Website without changing your settings, you agree to receive all cookies and other data tracking tools. On the other hand, if you do not wish to accept the use of these cookies, you can modify your cookie setting at any time. For more details, please refer to "How can you manage your consent?" section below.
</span>
        </p>
        <p class="p3">
          <span class="s4"><strong><br/></strong></span>
        </p>
        <p class="p3">
          <span class="s4"><strong>How can you manage your consent?
</strong></span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">You can accept or decline our use of cookies at any time by managing and/or disabling all or some of the cookies from your browser settings. However, by doing so, you may not be able to enjoy the full functionality of this Website.
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">Cookies settings can normally be found in the preference or settings menu from your browser, for example:
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Chrome: Settings -- Advanced -- Content Settings -- Cookies
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Safari: Preferences -- Privacy -- Cookies and website data
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Firefox: Preferences -- Privacy -- History
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Internet Explorer: Internet Options -- Privacy
</span>
        </p>
        <p class="p1">
          <span class="s1"><br/></span>
        </p>
        <p class="p1">
          <span class="s1">•&nbsp;Opera: Settings -- Privacy & security -- Cookies</span>
        </p>
        <p>
          <br/>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'CookieCn'
  };
</script>
<style scoped lang="less">
  table{
    td{padding:8px;}
  }
</style>
