<template>
  <div>
    <div class="h1">Privacy Policy</div>
    <div class="layout">
      <div class="rich-text">
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">This policy is intended to explain the practice of </span><span class="s2"> UNU TECHNOLOGY LIMITED</span><span
          class="s1">（</span><span class="s2">www.unutravel.com</span><span
          class="s1">） in collecting, retaining, storing and sharing personal data. </span><span class="s2"> UNU TECHNOLOGY LIMITED</span><span
          class="s1">reserves the right to amend this Privacy Policy and the updated version will be posted on this website.</span><br/>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">&nbsp;</span>
        </p>
        <p>
          <span style="font-size: 18px;"><strong>A.&nbsp;Privacy Policy</strong></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"> UNU TECHNOLOGY LIMITED</span><span
          class="s1">, the official website （<a href="http://www.unutravel.com"><span class="s3">www.unutravel.com</span></a>）), respects the privacy of visitors who provide personal information to the Company's websites and complies with the Personal Data (Privacy) Ordinance (Chapter 486 of the Laws of the Hong Kong Special Administrative Region) Provisions.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">1. </span><span class="s1">The company collects personal data from this website (definition see personal data (privacy)), may include but not limited to:
</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">You must provide the name, contact address, telephone number, e-mail account, transaction history, and voluntarily provide credit card number, bank name, Credit card valid date and other relevant data to order goods or request related services; visitor’s personal Computer network protocol address (IP addresses) and geography, etc., have browsed the time of this website, browser category and webpage that have browsed
</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">some personal data may be on the Internet via “Cookie Files” (Cookies Collection。 Cookie file is a personal computer Internet browser automatically archived in a Web site fragmentary data, can be accessed by the site. Cookies receive anonymous collective statistics and do not include personal contact information such as names, addresses, telephone numbers, and e-mail addresses.Most Internet browsers automatically use cookie files, and you can edit browser options to block this feature, but you may not be able to access certain pages on this site.
</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">If a visitor reaches an agreement with the company by using the services of the "UNU TRAVEL platform" and becomes a member of the "UNU TRAVEL platform", the information provided to the company by filling in the registration form at the time of registration, including but not Limited to the visitor's name, age, gender, email address and other information in the registration form; and other personal information voluntarily provided by the visitor to the company.
</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">2. </span><span
          class="s1">The personal data collected by the company on the Internet will be used by the company to provide services to you, to handle inquiries and complaints, and to conduct service investigations. Your personal data will also can be directly used by the company with your consent and used by direct marketing. If you are under the age of 13, you must seek the consent of your parents or your guardian before providing any personal information to the company. In addition, your personal data may also be transferred or transferred or disclosed to the following (including but not limited to) parties for the following purposes and purposes as required:</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">Application, daily operation (including but not limited to membership/user update), continued use and termination of the service of this website;
</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">Customers who advertise on the UNU TRAVEL website;</span>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"> The company’s agents, suppliers, contractors, partners or any other service providers for their use in the services provided on this website or related purposes;</span>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          Verify your identity;
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          Disclosure of personal information to any Hong Kong government agency in accordance with any legal requirements that are binding on the company: and internal statistical investigations and analysis.
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">3. </span><span
          class="s1">. If you do not want our company to use your personal data, you can voluntarily decide whether to provide basic personal data that is not marked as necessary. The purposes for which you can voluntarily provide your personal data are:
</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">To enable the company to better understand the characteristics of members or users of this "UNU TRAVEL website"; and to enable the company to provide relevant information, products or other services more suitable for your needs</span>

        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">4. </span><span class="s1">When our company collects your personal information, it will:</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          Explain to you that our company is collecting your personal data and the purposes for which it is collected;

        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          Give you the opportunity to object to the use of personal data for specific purposes: and explain how the company stores personal data, and the methods available for you to view, modify and delete stored personal data.
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">5. </span><span
          class="s1">The company intends to use the personal information provided by you for direct marketing; unless you have your consent (including your non-objection), the company will not use your personal information.
        </span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">6. </span><span
          class="s1">The company has internal staff rules to ensure that employees will not use your information for purposes other than those specified above, and remind employees to keep your personal information confidential. Regardless of how your personal information is stored, it will only be accessible to employees of the company who have been authorized to access such information. All personal data stored electronically will be protected by encryption and can only be accessed by authorized personnel on the "UNU TRAVEL website". Employees who are designated to be responsible for processing personal data will only be instructed to carry out relevant processing in accordance with the provisions of this Personal Data Collection Statement.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">7. </span><span
          class="s1">If for any reason (including recovering any money you owe the company), it is necessary for the company to take action against you in legal proceedings, you expressly agree to identify you and take legal action against you , Which can be used as a basis in the personal data provided by you.
</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">8. </span><span
          class="s1">The company retains your personal data for as long as necessary to implement the purpose for which your personal data was collected. The company may also keep personal data files for statistical purposes. The personal data will be deleted or destroyed until the data is no longer stored for any direct or related purposes.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">9. </span><span class="s1">You have the right to inquire to the company in writing whether the company owns your personal data, and to request a copy of such information and/or correction of such information. If you wish to make the above request, please contact our company （<a href="mailto:info@tvmost.com.hk"><span
          class="s5">info@unutravel.com</span></a></span><span
          class="s1">）to make the above request. After receiving the request, the company will process it as soon as possible (within 40 days).
</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">10. </span><span class="s1">This privacy policy may be updated by the company from time to time without prior notice to you. You can go to the following webpage （<a
          href="https://www.unutravel.com/about/privacy"><span class="s3">https://www.unutravel.com/about/privacy</span></a>）at any time to browse the latest version of this privacy policy.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p>
          <span style="font-size: 18px;"><strong>B.&nbsp;Disclaimer</strong></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">1. </span><span class="s1">The information contained on this website is prepared and produced for the various services provided by the " UNU TRAVEL Website" and its company. The company does not guarantee the completeness and accuracy of the information contained therein.
</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">2. </span><span class="s1">The company will try its best to keep the personal data stored in the " UNU TRAVEL website" system confidential to avoid unnecessary theft and use. However, due to the nature of the Internet, the company cannot fully guarantee the security and confidentiality of data transmission. Guarantee or statement. If you find that your personal information has been leaked without authorization, please contact our company immediately.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s2">3. </span><span class="s1">Other websites linked to " UNU TRAVEL Website" may also collect your personal information. The privacy policy of " UNU TRAVEL Website" will not apply to the personal information collected when visitors link to other websites from the " UNUTRAVE Website" and continue to read other websites. You are responsible for the relevant risks. No responsibility is assumed for the accuracy, validity, safety or any omissions of the information contained on other websites.</span>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'PrivacyCn'
  };
</script>
