<template>
  <div>
    <div class="h1">{{$t('contactUs')}}</div>
    <div class="contact-wrap">
      <contact-us/>
    </div>
  </div>
</template>
<script>
  import ContactUs from '../../webapp/components/ContactUs';

  export default {
    name: 'Contact',
    components: {ContactUs}
  };
</script>
<style scoped lang="less">
  .contact-wrap{padding:24px 0 60px;}
</style>
