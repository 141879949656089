<template>
  <div>
    <div class="h1">{{$t('aboutus')}}</div>
    <div class="layout rich-text">
      <ul style="padding:100px 0;list-style-type:disc;line-height:2em;font-size:16px;">
        <li style="padding:5px 0;">
          UNU Travel，香港又一科技有限公司的旗艦產品，致力構建未來的旅遊生態系統，率先提出並實現「平台科技＋文化旅遊＋親子遊學＋新媒體價值＋共享經濟」五位一體的國際文旅平台經濟模式。
        </li>
        <li style="padding:5px 0;">
          UNU Travel為旅遊業界提供一站式的商業解決方案及營銷策略，旅遊業界可零成本升級轉型，零成本打造自己的網上旅行社。完善的旅遊業務管理及營運系統，大幅降低業界經營成本，提升效益。助力業界騰出人手和精力研發更多滿足旅行者需求的產品/服務。
        </li>
        <li style="padding:5px 0;">
          透過UNU Travel，站在第一線的導遊領隊將有更大自主，帶自己想帶的團，走自己想走的線。
        </li>
        <li style="padding:5px 0;">
          透過UNU Travel，旅行者不再只是消費者，更可以達成一邊旅行一邊賺錢的夢想。
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AboutUsHk'
  };
</script>
