<template>
  <div class="main">
    <template v-if="language==='zh-cn'">
      <terms-and-conditions-cn v-if="current==='TermsAndConditions'"/>
      <about-us-cn v-else-if="current==='aboutus'"/>
      <contact v-else-if="current==='contactus'"/>
      <privacy-cn v-else-if="current==='privacy'"/>
      <cookie-cn v-else-if="current==='cookie'"/>
      <refund-cn v-else-if="current==='refund'"/>
    </template>
    <template v-else-if="language==='en'">
      <terms-and-conditions-en v-if="current==='TermsAndConditions'"/>
      <about-us-en v-else-if="current==='aboutus'"/>
      <contact v-else-if="current==='contactus'"/>
      <privacy-en v-else-if="current==='privacy'"/>
      <cookie-en v-else-if="current==='cookie'"/>
      <refund-en v-else-if="current==='refund'"/>
    </template>
    <template v-else>
      <terms-and-conditions-hk v-if="current==='TermsAndConditions'"/>
      <about-us-hk v-else-if="current==='aboutus'"/>
      <contact v-else-if="current==='contactus'"/>
      <privacy-hk v-else-if="current==='privacy'"/>
      <cookie-hk v-else-if="current==='cookie'"/>
      <refund-hk v-else-if="current==='refund'"/>
    </template>
  </div>
</template>
<script>
  import TermsAndConditionsCn from './components/TermsAndConditionsCn';
  import TermsAndConditionsHk from './components/TermsAndConditionsHk';
  import TermsAndConditionsEn from './components/TermsAndConditionsEn';
  import AboutUsCn from './components/AboutUsCn';
  import AboutUsHk from './components/AboutUsHk';
  import AboutUsEn from './components/AboutUsEn';
  import PrivacyCn from './components/PrivacyCn';
  import PrivacyHk from './components/PrivacyHk';
  import PrivacyEn from './components/PrivacyEn';
  import CookieHk from './components/CookieHk';
  import CookieCn from './components/CookieCn';
  import CookieEn from './components/CookieEn';
  import Contact from './components/Contact';
  import RefundHk from './components/RefundHk';
  import RefundEn from './components/RefundEn';
  import RefundCn from './components/RefundCn';
  import {scrollToY} from '../../utils';

  export default {
    name: 'About',
    components: {
      Contact,
      CookieCn,
      CookieHk,
      CookieEn,
      PrivacyHk,
      PrivacyCn,
      PrivacyEn,
      AboutUsHk,
      AboutUsCn,
      AboutUsEn,
      TermsAndConditionsHk,
      TermsAndConditionsCn,
      TermsAndConditionsEn,
      RefundHk,
      RefundEn,
      RefundCn
    },
    data() {
      return {
        language: '',
        current: ''
      };
    },
    created() {
      this.language = this.$i18n.locale;
      this.current = this.$route.params.type;
    },
    watch: {
      $route: function (value) {
        this.current = value.params.type;
        scrollToY();
      }
    }
  };
</script>
<style scoped lang="less">
  .main{
    /deep/ .h1{height:200px;line-height:200px;text-align:center;color:#fff;font-size:48px;font-weight:bold;text-shadow:3px 2px 0 #aaa;background-color:#ff6f61;background-image:linear-gradient(45deg, #fad7a1 0%, #fe6f60 100%);}
    /deep/ .rich-text{text-align:justify;}
  }
</style>
