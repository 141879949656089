<template>
  <div>
    <div class="h1">Refund Policy</div>
    <div class="layout">
      <div class="rich-text">
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>

        <p>
          <strong><span style="font-size: 18px;">1.1 Booking Confirmation</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          1.1.1 Certain Services are stated to be subject to instant confirmation. Other than these Services, any required time for confirmation as stated on the UNU TRAVEL Platform is solely for reference only. Actual time required for confirmation may vary.
        </p>
        <p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.2 Purchase and Use of the Vouchers</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1.2.1 Through the UNU TRAVEL Platform, you may purchase vouchers from UNU TRAVEL for the Services (“Vouchers”) offered by the Operators in the various destinations. Subject to the policy of the relevant Operator, you will receive an email confirmation of your purchase that will contain a Voucher confirmation number (“Confirmation Number”) and a printable version of your Voucher.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.2.2 In order to use your Voucher, you must appear in person at the meeting point designated by the relevant Operator on time, and present such documents and/or information as may be required by the Operator, that may include your Confirmation Number and/or your printed Voucher. If you fail to appear on time or to provide the required documents or information, no refunds will be granted.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.2.3 An Operator may also require you to provide an identification document bearing your photo in order to use your Voucher. Neither UNU TRAVEL nor the Operator is responsible for lost, destroyed or stolen Vouchers or Confirmation Numbers. Vouchers will be void if the relevant Services to be provided are prohibited by law. If you attempt to use a Voucher in an unlawful manner (e.g., you attempt to use a Voucher for wine tasting when you are under the legal age to do so), the respective Operator may refuse to accept your Voucher, and no refunds will be granted.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.3 Terms of the Vouchers</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.3.1 The Terms of Use for each Voucher may vary amongst Operators and any restrictions that apply to the use of such Voucher, including but not limited to a minimum age requirement, will be conveyed to you at the time of purchase on the UNU TRAVEL Platform.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.3.2 Vouchers are admission tickets to one-time events ('Events'): the date(s) on which a Voucher can be used will be stated on the Voucher. If you do not use your Vouchers on or by the date(s) noted on such Vouchers, except as expressly set forth therein, no refunds will be granted.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.4 Cancelation of Vouchers</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1.4.1 You may cancel your Voucher by contacting UNU TRAVEL customer service within the cancelation period, as stated at the time of purchase on the UNU TRAVEL Platform. Cancelation windows vary on a case by case basis. A Voucher canceled with the required notice will be refunded in full to the payment account you used to purchase such Voucher.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3">1.4.2 The Operator, not UNU TRAVEL, is the offeror of the Services for the Events, to which the Vouchers correspond to, and is solely responsible for accepting or rejecting any Voucher you purchase, as related to all such Services.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.4.3 Please directly consult with the Operator if you have any enquiries or complaints in respect of the Service you received in connection with your Voucher. Except as expressly set forth herein, all fees paid for Vouchers are non-refundable. Prices quoted for Vouchers are in the currency stated on the UNU TRAVEL Platform at the time prior to purchase.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1">1.4.4 If an Event which you have purchased a Voucher for is canceled by the Operator, UNU TRAVEL will notify you as soon as reasonably practicable, and will process a full refund to the payment account you used to purchase such Voucher.</span>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.5 Required Assistance</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.5.1 If you attempt to use a Voucher in accordance with this Terms of Use and the additional terms and conditions applicable to such Voucher and you are unable to do so (due to the fault of the Operator or otherwise), please contact UNU TRAVEL at support@unutravel.com, and UNU TRAVEL will try to liaise with the Operator for an appropriate remedy.
        </p>
        <p class="p1">
          <br/>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.6 Additional Charges</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.6.1 UNU TRAVEL reserves the right to require payment of fees or charges for any Services offered by UNU TRAVEL. You shall pay all applicable fees or charges, as described on the UNU TRAVEL Platform in connection with such Services selected by you.
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.7 Modifications to Charges</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.7.1 UNU TRAVEL reserves the right to change its price list for fees or charges at any time, upon notice to you, which may be sent to you by email or posted on the UNU TRAVEL Platform. Your use, or continued use, of the Services offered by UNU TRAVEL following such notification constitutes your acceptance of any new or revised fees or charges.
        </p>
        <p class="p1">
          <br/>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.8 UNU TRAVEL’s Rights and Obligations</span></strong>
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.8.1 UNU TRAVEL reserves the right to deny and cancel bookings or purchases of any Services that are deemed in violation of this policy. Such a determination is at UNU TRAVEL’s sole discretion.
        </p>
        <p class="p1">
          <br/>
        </p>
        <p style="text-indent: 2em;">
          1.8.2 UNU TRAVEL intends to offer or procure the Operators to offer the Services to you at the best price available on the market. You acknowledge and agree that all taxes and additional fees for particular Services that may be payable for using the Services are expressly excluded in determining the best price.
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
        <p style="text-indent: 2em;"><span
          class="s1">1.8.3 Whilst the Operators are required to provide UNU TRAVEL with accurate and updated prices of the Services on the UNU TRAVEL Platform, UNU TRAVEL cannot guarantee that all prices for the Services provided by the Operators are accurate and updated at all times.</span>
        </p>
        <p style="text-indent: 2em;">
          <span class="s1"><br/></span>
        </p>
        <p>
          <strong><span style="font-size: 18px;">1.9 The above terms and conditions & return policies are appliable to all UNU TRAVEL users worldwide.</span></strong>
        </p>
        <p style="text-indent: 2em;">
          <span class="s3"><br/></span>
        </p>
          <br/>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'RefundEn'
  };
</script>
<style scoped>
</style>
