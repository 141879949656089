<template>
  <div>
    <div class="h1">{{$t('aboutus')}}</div>
    <div class="layout rich-text">
      <ul style="padding:100px 0;list-style-type:disc;line-height:2em;font-size:16px;">
        <li style="padding:5px 0;">
          UNU Travel，香港又一科技有限公司的旗舰产品，致力构建未来的旅游生态系统，率先提出并实现「平台科技＋文化旅游＋亲子游学＋新媒体价值＋共享经济」五位一体的国际文旅平台经济模式。
        </li>
        <li style="padding:5px 0;">
          UNU Travel为旅游业界提供一站式的商业解决方案及营销策略，旅游业界可零成本升级转型，零成本打造自己的网上旅行社。完善的旅游业务管理及营运系统，大幅降低业界经营成本，提升效益。助力业界腾出人手和精力研发更多满足旅行者需求的产品/服务。
        </li>
        <li style="padding:5px 0;">
          透过UNU Travel，站在第一线的导游领队将有更大自主，带自己想带的团，走自己想走的线。
        </li>
        <li style="padding:5px 0;">
          透过UNU Travel，旅行者不再只是消费者，更可以达成一边旅行一边赚钱的梦想。
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'AboutUsCn'
  };
</script>
